<template>
  <div class="CourseDetail">
    <div style="display: flex;justify-content: center;">
      <Location :locationMsg="locationMsg" style="margin-left:-60px;"></Location>
    </div>
    <div class="apply">
      <p class="title">{{ courseIntro.course_name }}</p>
      <p class="describe" v-if="courseIntro.memo">{{ courseIntro.memo }}</p>
      <!-- <div class="bgColor"></div> -->
      <!-- <p class="subTitle">
        {{ courseIntro.memo }}
      </p> --> 
          <el-button class="submit" @click="signup"  v-show="buttonState"
          ><div class="content"><img  src="../../assets/images/形状.png" alt=""><span>立即报名</span></div></el-button
        >
       <el-image  class="course-img" v-if="courseIntro.course_photo" :src="courseIntro.course_photo ? httpConfig.baseURL+courseIntro.course_photo : ''" alt="" />

       <el-button class="submit1" v-show="registered">
        <div class="content">
          <span>已报名</span>
        </div>  
       </el-button>

      <!-- 报名单--> 
      <!-- <el-button class="submit" @click="signup"
        ><div class="content">
          <img src="../../assets/images/形状.png" alt="" /><span>立即报名</span>
        </div></el-button
      > -->
      <!-- 报名单-->
      <el-dialog
        :title="'报名'"
        :visible.sync="dialogFormVisible"
        @closed="closeDialog"
      >
      <div class="signup-lineBox"></div>
      <div class="course-name">
        <div class="course-title">课程</div>
        <div class="course-describe">{{ courseIntro.course_name }}</div>
      </div>
      <div class="signup-lineBox1"></div>
        <el-form
          :rules="rules"
          :model="form"
          ref="ruleForm"
          style="z-index: 99999"
        >
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              clearable
              placeholder="请输入姓名"
              class="input_title"
              @blur="inputblur"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile">
            <el-input
              v-model="form.mobile"
              autocomplete="off"
              clearable
              placeholder="请输入手机号码"
              maxlength="11"
              @blur="inputMobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="form.email"
              autocomplete="off"
              clearable
              placeholder="请输入正确的邮箱账号"
              @blur="inputEmail"
            ></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="signUp('ruleForm')"
            >保 存</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div class="lineBox"></div>
    <div class="info">
      <Info :courseInfo="courseIntro"></Info>
    </div>
    <div class="lineBox"></div>
    <div class="courseInfo">
      <el-menu
        :default-active="activeIndex"
        @select="handleSelect"
        active-text-color="rgb(2, 167, 240)"
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item index="Intro">课程介绍</el-menu-item>
        <!-- <el-menu-item index="Info">基本信息</el-menu-item> -->
        <el-menu-item index="Apply"
          >已报名({{
            courseIntro.childVOs && courseIntro.childVOs.length
          }}人)</el-menu-item
        >
      </el-menu>
      <!-- <router-view :courseInfo="courseIntro"/> -->
      <component
        :key="'child' + activeIndex"
        :is="activeIndex"
        :courseInfo="courseIntro"
        crm-type="customer"
      />
    </div>
  </div>
</template>
<script>
import Info from "./Info.vue";
import Apply from "./Apply.vue";
import Intro from "./Intro.vue";
import Location from "../../components/Location.vue";
import { getTrainingcourseById } from "../../api/vocational/vocational.js";
import { vocationalSignup } from "../../api/common";
import { Message } from "element-ui";
import httpConfig from "../../common/utils/config";
import { getPlainText } from "../../common/utils/tool";
export default {
  data() {
    return {
      httpConfig,
      courseIntro: {
        head: "精通JAVA/高并发/微服务/分布式/中间件",
        subhead: "-5大技能专题，8大网易实战项目，带你成为高级架构师-",
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      buttonState:true,
      // 已报名按钮
      registered:false,
      form: {
        fk: "",
        name: "",
        mobile: "",
        email: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min:11 , max:11 , message:"请输入11位手机号码"}
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      formLabelWidth: "120px",
      openeds: ["1"],
      activeIndex: "Intro",
      locationMsg: [
        { name: "职业培训", router: "Vocational" },
        { name: "课程详情", router: "vocational/course/intro" },
      ],
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  methods: {
    handleSelect(key, keyPah) {
      this.activeIndex = key;
    },
    async getData(id) {
      let res = await getTrainingcourseById({ id: id });

      this.courseIntro = res.data;
      this.courseIntro.course_desc = getPlainText(getPlainText(this.courseIntro.course_desc))
      console.log("单个课程详情数据:",this.courseIntro)
    },
    signUp(formName) {
      // console.log(this.form.name)
      // if(this.form.name == ''){
      //   Message.warning({message:'请输入姓名',duration: 2000,customClass: 'messageBox'})
      // }
      // else if(this.form.mobile == ''){
      //    Message.warning({message:'请输入手机号',duration: 2000,customClass: 'messageBox'})
      // }
      // else if(this.form.email == ''){
      //   Message.warning({message:'请输入邮箱',duration: 2000,customClass: 'messageBox'})
      // }
      let _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.form.fk = _this.courseIntro.billid;
          vocationalSignup(_this.form).then((res) => {
            if (res && res.success) {
              // Message.success("报名成功");
              Message({
                type: "success",
                message: "报名成功",
                customClass: "messageBox",
              });
              let id = _this.$route.query.id;
              _this.getData(id);
              _this.closeDialog();
              console.log("id是:",id)
              this.buttonState = false;
              this.registered = true;
            } else {
              Message.error("姓名、联系电话、邮箱已存在，不能重复报名");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    inputblur() {
      if (this.form.name == "") {
        // this.$message.warning('qing')
        Message.warning({
          message: "请输入姓名",
          duration: 2000,
          customClass: "messageBox",
        });
      }
    },
    inputMobile() {
      if (this.form.mobile == "") {
        Message.warning({
          message: "请输入手机号",
          duration: 2000,
          customClass: "messageBox",
        });
      }
    },
    inputEmail() {
      if (this.form.email == "") {
        Message.warning({
          message: "请输入邮箱",
          duration: 2000,
          customClass: "messageBox",
        });
      }
    },
    closeDialog() {
      this.form.name = "";
      this.form.mobile = "";
      this.form.email = "";
      this.dialogFormVisible = false;
    },
    signup() {
      this.dialogFormVisible = true;
      this.$refs["ruleForm"].clearValidate();
    },
  },
  components: { Apply, Info, Intro, Location },
};
</script>
<style scoped lang="scss">
.CourseDetail {
  width: 100%;
  min-height: 768px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.apply {
  margin: 0 auto;
  width: 756px;
  // height: 100%;
  height: 160px;
  /* height: 200px; */
  // text-align: center;
  margin-bottom: 0px;
  font-size: 42px;
  font-family: Regular;
  font-weight: 400;
  // background: #1446c1;
  position: relative;
  .bgColor {
    position: absolute;
    width: 36%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    // background: #1a1491;
    // background-color: #231f6a;
    // background-image: linear-gradient(90deg, #231f6a 0%, #1a1491 100%);
  }
  .bgColorjb {
    width: 10%;
    top: 0;
    right: 25%;
    height: 100%;
    z-index: 1;
    background: #1a1491;
  }
  .title {
    // font-size: 42px;
    // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    // font-weight: 400;
    // color: #ffffff;
    // letter-spacing: 3px;
    // z-index: 3;
    // position: absolute;
    // top: 40px;
    // left: 50%;
    // transform: translateX(-50%);
    position: absolute;
    left: 0px;
    top: 25px;
    font-size: 24px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #101010;
    line-height: 36px;
  }
  .describe {
    position: absolute;
    left: 0px;
    top: 66px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #404040;
    line-height: 21px;
  }
  // .subTitle {
  //   position: absolute;
  //   top: 108px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   font-size: 24px;
  //   font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  //   font-weight: 400;
  //   color: #ffffff;
  //   z-index: 3;
  // }
  .submit {
    // width: 110px;
    // height: 34px;
    position: absolute;
    top: 100px;
    left: 70px;
    transform: translateX(-50%);
    padding: 10px 32px;
    // background: #fe8d0a;
    background: #2885ff;
    border-radius: 6px;
    font-size: 14px;
    font-family: Medium;
    font-weight: 500;
    color: #ffffff;
    border: none;
    text-align: center;
    .content {
      text-align: center;
      vertical-align: middle;
    }
    img {
      padding-right: 8px;
      
    }
  }
  .submit1{
    // width: 110px;
    // height: 34px;
    position: absolute;
    top: 100px;
    left: 70px;
    transform: translateX(-50%);
    padding: 11px 50px;
    // background: #fe8d0a;
    background: #F6F6F6;
    border-radius: 6px;
    border: none;
    text-align: center;
    .content {
      text-align: center;
      vertical-align: middle;
      span{
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #999999;

      }
    }
  }
  .course-img{
    position: absolute;
    top: 24px;
    right: 30px;
    width: 184px;
    height: 110px;
    border-radius: 6px;
  }
}
.lineBox {
  margin: 0 auto;
  width: 756px;
  height: 1px;
  background: #F1F1F1;
}
.info {
  margin: 0 auto;
  width: 756px;
  height: 96px;
}
.courseInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // background: red;
  .el-menu {
    width: 756px;
    // margin-left: 562px;
    height: 62px;
    background: #ffffff;
    border-bottom: none;
    padding-left: 0px;
  }
  .el-menu--horizontal > .el-menu-item {
    width: 100px;
    height: 14px;
    font-size: 14px;
    font-family: Medium;
    font-weight: 500;
    color: #8896a7;
    border: none !important;
    height: unset;
    padding-left: 0px;
    &:hover {
      background: #ffffff;
    }
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border: none;
    font-size: 14px;
    font-family: Regular;
    font-weight: 400;
    color: #101010 !important;
    background: #ffffff !important;
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 10px;
      // bottom: -10px;
      width: 56px;
      height: 2px;
      background: #2885ff;
      border-radius: 5px;
      left: 28%;
      transform: translateX(-50%);
    }
  }
}
</style>
<style>
/* .el-form-item__error{
  color: #2885FF;
} */
.el-dialog {
  width: 356px;
  height: 463px;
  /* width: 25%;
  height: 60%; */
  background: #ffffff;
  border-radius: 16px;
  position: relative;
}
.el-dialog__header {
  width: 356px;
  /* background: red; */
  height: 28px;
  /* border-bottom: 1px solid #ddd; */
  /* background: red; */
}
.el-dialog__footer {
  position: relative;
  right: -3px;
  bottom: 135px;
}
.el-dialog__title {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #101010;
  position: absolute;
  left: 16px;
  top: 12px;
}
.el-dialog__body {
  /* width: 356px; */
  height: 463px;
  padding: 0;
  /* background: blue; */
  display: flex;
  justify-content: center;
}
/* .course-description{
  width: 356px;
  height: 84px;
  background: pink;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
} */
/* .lineBox {
    width: 324px;
height: 1px;
background: #F1F1F1;
border-radius: 8px;
  } */

.el-form {
  /* background: pink; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 65px;
}
.course-name{
  width: 356px;
  height: 84px;
  position: absolute;
  /* background: pink; */
  top: 47px;
  display: flex;
  justify-content: space-between;
}
.course-title{
  width: 30px;
  height: 12px;
  margin-top: 23px;
  margin-left: 16px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #8896A7;
  line-height: 24px;
}
.course-describe{
  width: 288px;
  height: 36px;
  margin-top: 23px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #404040;
  line-height: 24px;
}
.signup-lineBox{
  position: relative;
  margin-top: -10px;
  width: 356px;
  height: 1px;
  background: #F1F1F1;
}
.signup-lineBox1{
  width: 324px;
  height: 1px;
  background: #F1F1F1;
  border-radius: 8px;
  position: absolute;
  top: 130px;
}
.el-form-item__label {
  /* background: red;   */
  text-align: left;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #101010;
  line-height: 21px;
  width: 356px;
  height: 14px;
  padding-left: 26px;
  position: relative;
}
/* .el-form-item__label::before{
  content: '.';
  color: #FF1C1C;
  width: 4px;
  height: 4px;
  border-radius: 50%;
} */

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  width: 4px;
  height: 4px;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 16px;
}
.el-form-item__content {
  width: 324px;
  height: 36px;
  /* margin-top: 8px; */
  margin: 12px 16px 24px 16px;
}
/* .el-form-item{
  background: red; */
/* display: flex;
  flex-direction: column;
} */
.el-form-item {
  /* background: red; */
  height: 62px;
  display: flex;
  flex-direction: column;
}
/* .el-button--primary{
  background: red;
} */
.el-button {
  padding: 10px 32px;
}
.el-form-item__error {
  /* display: none; */
}
.el-message--success {
  /* width: 62px; */
  height: 52px;
  background: #ffffff;
  box-shadow: 0px 12px 50px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
}
.el-icon-success::before {
  /* font-size: 20px; */
  content: url("../../assets/images/success.png");
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
}

.el-message--success .el-message__content {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #2885ff;
}

::v-deep .el-message--warning {
  width: 158px;
  height: 52px;
  background: #ffffff;
  box-shadow: 0px 12px 50px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
}

.el-icon-warning::before {
  /* content: '\e79c'; */
  content: url("../../assets/images/warning.png");
  /* content: url(''); */
  width: 20px;
  height: 20px;
  display: inline-block;
  /* background-image: url("../../assets/images/warning.png"); */
  background-size: 100%;
}
.el-message--warning .el-message__content {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ff1c1c;
}

/* .el-icon-success:before{
  content: '\e79c';
} */
</style>

<style>
@media screen and (max-width: 768px) {
}
</style>