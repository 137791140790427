import request from '../common/utils/request'


// 课程报名
export function vocationalSignup(data) {
    return request({
      url: "/itf/common/cjrh/education/signup.json",
      method: "get",
      params: data
    });
}
  