import request from '../../common/utils/request'

//查询课程列表
export function getTrainingcourses(data) {
  return request({
    url: "/itf/common/cjrh/education/getTrainingcourses.json",
    method: "get",
    params: data
  });
}

//查询课程详情
export function getTrainingcourseById(data) {
  return request({
    url: "/itf/common/cjrh/education/getTrainingcourseById.json",
    method: "get",
    params: data
  });
}
